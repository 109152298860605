<template>
  <div class="image-container">
    <canvas ref="canvasBackground" width="900" height="600"></canvas>
    <canvas ref="canvasForeground" width="900" height="600"></canvas>
  </div>
</template>
<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      backgroundImage: '/assets/kitchen.png',
      foregroundImages: [
        {
          image: '/assets/buzdolabı.png',
          x: 0,
          y: 0,
          width: 200,
          height: 200,
          isDragging: false,
          isResizing: false,
          isSelected: false,
          resizeHandle: null
        },
        {
          image: '/assets/tabak.png',
          x: 100,
          y: 100,
          width: 200,
          height: 200,
          isDragging: false,
          isResizing: false,
          isSelected: false,
          resizeHandle: null
        },
        {
          image: '/assets/baba.png',
          x: 200,
          y: 200,
          width: 100,
          height: 200,
          isDragging: false,
          isResizing: false,
          isSelected: false,
          resizeHandle: null
        }
        // Add more foreground images here...
      ],
      resizeHandleSize: 20
    }
  },
  mounted() {
    this.loadBackgroud();
    this.loadImages();
    this.handleMouseEvents();
  },
  methods: {
    loadBackgroud() {
      let canvas = this.$refs.canvasBackground;
      let ctx = canvas.getContext('2d');
      // Clear canvas
      // ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Load background image
      let background = new Image();
      background.onload = () => {
        // Draw the background image
        ctx.drawImage(background, 0, 0, canvas.width, canvas.height);


      };
      background.src = this.backgroundImage;
    },
    loadImages() {
      let canvas = this.$refs.canvasForeground;
      let ctx = canvas.getContext('2d');

      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // let foregrounds = targetForeground ? [targetForeground] : this.foregroundImages;
      // Load foreground images
      this.foregroundImages.forEach((foreground) => {
        let image = new Image();
        image.onload = () => {
          // Draw the foreground image
          ctx.drawImage(image, foreground.x, foreground.y, foreground.width, foreground.height);

          // Draw resize handles if the foreground image is selected
          if (foreground.isSelected) {
            ctx.fillStyle = 'red';
            // Top left
            ctx.fillRect(foreground.x - this.resizeHandleSize / 2, foreground.y - this.resizeHandleSize / 2, this.resizeHandleSize, this.resizeHandleSize);
            // Top right
            ctx.fillRect(foreground.x + foreground.width - this.resizeHandleSize / 2, foreground.y - this.resizeHandleSize / 2, this.resizeHandleSize, this.resizeHandleSize);
            // Bottom left
            ctx.fillRect(foreground.x - this.resizeHandleSize / 2, foreground.y + foreground.height - this.resizeHandleSize / 2, this.resizeHandleSize, this.resizeHandleSize);
            // Bottom right
            ctx.fillRect(foreground.x + foreground.width - this.resizeHandleSize / 2, foreground.y + foreground.height - this.resizeHandleSize / 2, this.resizeHandleSize, this.resizeHandleSize);
          }
        };
        image.src = foreground.image;
      });
    },
    handleMouseEvents() {
      let canvas = this.$refs.canvasForeground;

      const startAction = (x, y) => {
        let selectedForeground = null;

        // Check if the action starts inside any of the foreground images
        this.foregroundImages.forEach((foreground) => {
          if (
            x >= foreground.x && x <= foreground.x + foreground.width &&
            y >= foreground.y && y <= foreground.y + foreground.height
          ) {
            foreground.isSelected = true;
            selectedForeground = foreground;

            // Check if the action starts on one of the resize handles
            if (Math.abs(x - foreground.x) <= this.resizeHandleSize && Math.abs(y - foreground.y) <= this.resizeHandleSize) {
              foreground.isResizing = true;
              foreground.resizeHandle = "top-left";
            } else if (Math.abs(x + this.resizeHandleSize - (foreground.x + foreground.width)) <= this.resizeHandleSize && Math.abs(y - foreground.y) <= this.resizeHandleSize) {
              foreground.isResizing = true;
              foreground.resizeHandle = "top-right";
            } else if (Math.abs(x - foreground.x) <= this.resizeHandleSize && Math.abs(y - (foreground.y + foreground.height)) <= this.resizeHandleSize) {
              foreground.isResizing = true;
              foreground.resizeHandle = "bottom-left";
            } else if (Math.abs(x - (foreground.x + foreground.width)) <= this.resizeHandleSize && Math.abs(y - (foreground.y + foreground.height)) <= this.resizeHandleSize) {
              foreground.isResizing = true;
              foreground.resizeHandle = "bottom-right";
            } else {
              foreground.isDragging = true;
            }
          } else {
            foreground.isSelected = false;
          }
        });

        if (selectedForeground) {
          this.loadImages();
        }
      };

      const moveAction = (x, y) => {
        this.foregroundImages.forEach((foreground) => {
          if (foreground.isDragging) {
            foreground.x = x - foreground.width / 2;
            foreground.y = y - foreground.height / 2;
            this.loadImages();
          }

          if (foreground.isResizing) {
            if (foreground.resizeHandle === "top-left") {
              foreground.width = foreground.x + foreground.width - x;
              foreground.height = foreground.y + foreground.height - y;
              foreground.x = x;
              foreground.y = y;
            } else if (foreground.resizeHandle === "top-right") {
              foreground.width = x - foreground.x;
              foreground.height = foreground.y + foreground.height - y;
              foreground.y = y;
            } else if (foreground.resizeHandle === "bottom-left") {
              foreground.width = foreground.x + foreground.width - x;
              foreground.height = y - foreground.y;
              foreground.x = x;
            } else if (foreground.resizeHandle === "bottom-right") {
              foreground.width = x - foreground.x;
              foreground.height = y - foreground.y;
            }

            foreground.width = foreground.width >= 10 ? foreground.width : 10;
            foreground.height = foreground.height >= 10 ? foreground.height : 10;
            this.loadImages();
          }
        });
      };

      const endAction = () => {
        this.loadImages();
        this.foregroundImages.forEach((foreground) => {
          foreground.isDragging = false;
          foreground.isResizing = false;
          foreground.resizeHandle = null;
        });
      };

      // Mouse events
      canvas.addEventListener('mousedown', (event) => {
        let rect = canvas.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;
        startAction(x, y);
      });

      canvas.addEventListener('mousemove', (event) => {
        let rect = canvas.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;
        moveAction(x, y);
      });
      canvas.addEventListener('mouseup', endAction);

      // Touch events
      canvas.addEventListener('touchstart', (event) => {
        let rect = canvas.getBoundingClientRect();
        let x = event.touches[0].clientX - rect.left;
        let y = event.touches[0].clientY - rect.top;
        startAction(x, y);
        event.preventDefault();
      }, { passive: false });

      canvas.addEventListener('touchmove', (event) => {
        let rect = canvas.getBoundingClientRect();
        let x = event.touches[0].clientX - rect.left;
        let y = event.touches[0].clientY - rect.top;
        moveAction(x, y);
        event.preventDefault();
      }, { passive: false });

      canvas.addEventListener('touchend', endAction);
    }
  }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>

<style>
.image-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.image-container canvas {
  position: absolute;
  top: 0;
  left: 0;
}
</style>